import * as Sentry from '@sentry/node'

import { getCatalogCategoryProducts } from '@/services/catalog/getCatalogProducts'
import { getProductsData } from '@/services/content/getProductsData'
import { getReviewsList } from '@/services/content/getReviewsList'

import { buildServerSideContext, buildServerSideProps } from '@/utils/buildServerSide'
import { getPrismicRef } from '@/utils/prismicPreview'
import baseRouter from '@/utils/baseRouter'

import CATEGORIES from '@/temp-configs/category-constants'

import HomeView from '@/views/Home'

const router = baseRouter().get(async (req, res) => {
    const pageData = {
        title: 'Smarter Luxury Sleep',
        pageType: 'homepage',
        excludedScripts: [],
        products: [],
        reviewList: []
    }

    const productsCategory = CATEGORIES.MATTRESSES
    const prismicRef = getPrismicRef(req)

    try {
        const [catalogProducts, productsContent, reviewList] = await Promise.all([
            getCatalogCategoryProducts(productsCategory),
            getProductsData(productsCategory, prismicRef),
            getReviewsList('homepage', prismicRef)
        ])

        const products = catalogProducts.map(product => {
            const content = productsContent?.find(({ sku }) => product.productCode === sku)

            if (content) {
                product.content = {
                    ...product.content,
                    ...content
                }
            }

            return product
        })

        pageData.products = products
        pageData.reviewList = reviewList
    } catch (error) {
        console.log(`[pages.home]: Error fetching products: ${error.message}`)
        Sentry.captureException(error)
    }

    return {
        props: buildServerSideProps(pageData, res.locals)
    }
})

export function getServerSideProps(context) {
    const { req, res } = buildServerSideContext(context)
    return router.run(req, res)
}

export default function HomePage(props) {
    return (
        <HomeView {...props} />
    )
}
